<template>
	<div class="new_expense_cature_modal_wrapper" :ref="customRef">
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="modal"
			v-model="isOpen"
			ok-variant="success"
			size="xl"
			:title="FormMSG(5, 'Picture capture')"
			:ok-title="FormMSG(201, 'Save')"
			:cancel-title="FormMSG(202, 'Cancel')"
			:class="rendModalClass"
			@ok="saveCapturedPics"
			@cancel="handleCloseModal"
			@hidden="handleCloseModal"
			modal-class="mui-animation"
			:fade="false"
		>
			<b-row class="expensecapture_modal_container">
				<b-col v-show="isImagesListVisible" class="side_images_list" md="2">
					<CapturedImgList
						v-show="capture.states.hasMultipleImg"
						v-model="capture.states.images"
						:key="capture.states.instanceToken"
						@crop="handleCropImage"
					/>
				</b-col>
				<b-col class="side_actions" :md="isImagesListVisible ? '10' : '12'">
					<div class="centered_content" style="margin-bottom: 10px">
						<b-button-group v-show="!capture.states.crop.open">
							<b-button :variant="rendToggleModeBtnVariant('upload')" @click="capture.dispatch('setTypeSource', 'upload')">
								{{ FormMSG(1, 'Upload Image') }}
							</b-button>
							<b-button :variant="rendToggleModeBtnVariant('camera')" @click="capture.dispatch('setTypeSource', 'camera')">
								{{ FormMSG(2, 'From WebCam') }}
							</b-button>
						</b-button-group>
						<b-button v-show="capture.states.crop.open" variant="outline-danger" @click="cancleCropImageAction">
							{{ FormMSG(3, 'Cancel Crop') }}
						</b-button>
					</div>

					<div v-if="capture.states.crop.open" class="mod_img">
						<CropPicture
							v-bind="$props"
							ref="myCropPicture"
							:key="capture.states.instanceToken"
							:has-validation-button="!autoCropCapture"
							:base-url-img="capture.states.crop.currentImg"
							:type="type"
							@change="handleCropImgChange"
							@cropped="handleAutoCrop"
						/>
					</div>
					<div v-else class="get_img">
						<GetDesktopImg v-if="capture.checkTypeSource('upload')" @change="handleDesktopImageSelected" />
						<CapturePicture
							v-if="capture.checkTypeSource('camera')"
							:openned="capture.checkTypeSource('camera') && value"
							has-no-file-uploader
							has-validation-button
							@change="handleCaptureChange"
						/>
					</div>
				</b-col>
			</b-row>

			<template #modal-footer="{ ok, cancel }">
				<b-button size="sm" variant="outline-danger" @click="cancel()">
					{{ FormMSG(5454464, 'Cancel') }}
				</b-button>
				<!-- Button with custom close trigger value -->
				<b-button size="sm" variant="success" :disabled="!isCanSubmitCapture" @click="ok()">
					{{ FormMSG(798978789, 'Ok') }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { makeID, isNil } from '~utils';
import mapProps from '@/shared/vuePropsMapper';

import CaptureStore from './capture.store';
import captureMixin from './capture.mixin';
import languageMessages from '@/mixins/languageMessages';

import CapturedImgList from '@/components/ExpenseService/CapturedImgList';
import GetDesktopImg from '@/components/GetDesktopImg';
import CapturePicture from '@/components/CapturePicture';
import CropPicture from '@/components/CropPicture';

// const authorizedParentsTypes = [
//   "temporary",
//   "expense_item",
//   "greenfilm",
//   "profile_picture",
//   "project",
//   "user",
//   "location"
// ];

export default {
	name: 'CaptureModuleMainComponent',
	components: {
		CapturedImgList,
		CapturePicture,
		GetDesktopImg,
		CropPicture
	},
	mixins: [captureMixin, languageMessages],
	props: {
		...mapProps(['multipleImg', 'camModeFirst', 'autoCropCapture'], {
			type: Boolean,
			required: false,
			default: false
		}),
		...mapProps(['parentType', 'parentSubType'], {
			type: String,
			required: false,
			default: null
			// validator: v => authorizedParentsTypes.includes(v)
		}),

		customRef: {
			type: String,
			required: false,
			default: 'capturePicComponent'
		},

		editData: {
			type: Object,
			required: false,
			default: null
		},

		type: {
			type: String,
			required: false,
			default: 'default',
			validator: (v) => ['default', 'avatar'].includes(v)
		},

		// Parents options
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		}
	},
	data() {
		const capture = new CaptureStore(this, {
			captureID: makeID(10),
			hasMultipleImg: this.multipleImg,
			images: isNil(this.editData) ? [] : this.editData.images
		});

		return {
			capture
		};
	},
	computed: {
		/**
		 * @return {Object}
		 */
		rendModalClass() {
			return {
				'has-multiple-images': this.capture.states.hasMultipleImg
			};
		},

		/**
		 * @return {Boolean}
		 */
		isImagesListVisible() {
			const _ = this.capture.states;
			return _.hasMultipleImg && _.length;
		},

		/**
		 * @return {Boolean}
		 */
		isCanSubmitCapture() {
			return this.capture.states.images.length > 0;
		}
	},
	created() {
		this.capture.dispatch('initStore');
		if (this.camModeFirst) this.capture.dispatch('setTypeSource', 'camera');
	},
	methods: {
		/**
		 * @param {String} type
		 * @return {String}
		 */
		rendToggleModeBtnVariant(type) {
			const cs = this.capture.checkTypeSource;
			return `${cs(type) ? '' : 'outline-'}primary`;
		},

		async saveCapturedPics() {
			this.capture.dispatch('setLoading');
			await this.loopImages(this.capture.states.images);
			const savedImgs = this.capture.states.savedImages;
			this.capture.dispatch('setLoading', false);
			this.$emit('change', savedImgs);
		},

		cancleCropImageAction() {
			this.capture.dispatch('removeCurrentCrop');
		},

		/**
		 * @param {Object} elem
		 */
		handleCropImage(elem) {
			if (isNil(elem)) return;
			this.capture.dispatch('setCurrImgToCrop', elem);
		},

		/**
		 * @param {Object} cropped
		 */
		handleCropImgChange(cropped) {
			if (isNil(cropped)) return;
			this.capture.dispatch('replaceImgOnList', cropped);
			this.cancleCropImageAction();
		},

		/**
		 * @param {String} img
		 */
		handleDesktopImageSelected(img) {
			if (isNil(img)) return;
			this.capture.dispatch('pushImgToList', img);
		},

		/**
		 * @param {String} img
		 */
		handleAutoCrop(img) {
			if (!this.autoCropCapture) return;
			this.capture.dispatch('replaceImgOnList', { index: 0, img });
		},

		/**
		 * @param {Object} capture
		 */
		handleCaptureChange(capture) {
			if (isNil(capture)) return;
			console.log({ handleCaptureChange: capture });
			if (this.autoCropCapture) this.capture.dispatch('setCurrImgToCrop', capture);
			this.capture.dispatch('pushImgToList', capture.img);
		},

		handleCloseModal() {
			this.isOpen = false;
		}
	}
};
</script>
