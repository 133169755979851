var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: _vm.customRef, staticClass: "new_expense_cature_modal_wrapper" },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          class: _vm.rendModalClass,
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "ok-variant": "success",
            size: "xl",
            title: _vm.FormMSG(5, "Picture capture"),
            "ok-title": _vm.FormMSG(201, "Save"),
            "cancel-title": _vm.FormMSG(202, "Cancel"),
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: _vm.saveCapturedPics,
            cancel: _vm.handleCloseModal,
            hidden: _vm.handleCloseModal,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(5454464, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "success",
                        disabled: !_vm.isCanSubmitCapture,
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(798978789, "Ok")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "expensecapture_modal_container" },
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isImagesListVisible,
                      expression: "isImagesListVisible",
                    },
                  ],
                  staticClass: "side_images_list",
                  attrs: { md: "2" },
                },
                [
                  _c("CapturedImgList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.capture.states.hasMultipleImg,
                        expression: "capture.states.hasMultipleImg",
                      },
                    ],
                    key: _vm.capture.states.instanceToken,
                    on: { crop: _vm.handleCropImage },
                    model: {
                      value: _vm.capture.states.images,
                      callback: function ($$v) {
                        _vm.$set(_vm.capture.states, "images", $$v)
                      },
                      expression: "capture.states.images",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "side_actions",
                  attrs: { md: _vm.isImagesListVisible ? "10" : "12" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "centered_content",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.capture.states.crop.open,
                              expression: "!capture.states.crop.open",
                            },
                          ],
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: _vm.rendToggleModeBtnVariant("upload"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.capture.dispatch(
                                    "setTypeSource",
                                    "upload"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "Upload Image")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: _vm.rendToggleModeBtnVariant("camera"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.capture.dispatch(
                                    "setTypeSource",
                                    "camera"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(2, "From WebCam")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.capture.states.crop.open,
                              expression: "capture.states.crop.open",
                            },
                          ],
                          attrs: { variant: "outline-danger" },
                          on: { click: _vm.cancleCropImageAction },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(3, "Cancel Crop")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.capture.states.crop.open
                    ? _c(
                        "div",
                        { staticClass: "mod_img" },
                        [
                          _c(
                            "CropPicture",
                            _vm._b(
                              {
                                key: _vm.capture.states.instanceToken,
                                ref: "myCropPicture",
                                attrs: {
                                  "has-validation-button": !_vm.autoCropCapture,
                                  "base-url-img":
                                    _vm.capture.states.crop.currentImg,
                                  type: _vm.type,
                                },
                                on: {
                                  change: _vm.handleCropImgChange,
                                  cropped: _vm.handleAutoCrop,
                                },
                              },
                              "CropPicture",
                              _vm.$props,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "get_img" },
                        [
                          _vm.capture.checkTypeSource("upload")
                            ? _c("GetDesktopImg", {
                                on: { change: _vm.handleDesktopImageSelected },
                              })
                            : _vm._e(),
                          _vm.capture.checkTypeSource("camera")
                            ? _c("CapturePicture", {
                                attrs: {
                                  openned:
                                    _vm.capture.checkTypeSource("camera") &&
                                    _vm.value,
                                  "has-no-file-uploader": "",
                                  "has-validation-button": "",
                                },
                                on: { change: _vm.handleCaptureChange },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }